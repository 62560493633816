export const Regions = {
    Region_EMEA: 'EMEA',
    Region_APAC: 'APAC',
    Region_UK: 'UK',
    Region_US: 'US',
    Region_OTHER: 'OTHER'
}

export const RegionSelectOptions = [
    { value: Regions.Region_US, label: "North America" },
    { value: Regions.Region_UK, label: "United Kingdom" },
    { value: Regions.Region_EMEA, label: "Europe (Outside UK)" },
    { value: Regions.Region_APAC, label: "Japan" }
]

export const getRegionCode = (labelName) =>{
    let obj = RegionSelectOptions.find(it => it.label === labelName);
    return obj.value;
}

export const getSpecsRegion = (countryCode) => {
    switch (countryCode) {
        case 'AX':
        case 'AL':
        case 'AD':
        case 'AT':
        case 'BY':
        case 'BE':
        case 'BA':
        case 'BG':
        case 'HR':
        case 'CZ':
        case 'DK':
        case 'EE':
        case 'FO':
        case 'FI':
        case 'FR':
        case 'DE':
        case 'GI':
        case 'GR':
        case 'GG':
        case 'VA':
        case 'HU':
        case 'IS':
        case 'IE':
        case 'IM':
        case 'IT':
        case 'JE':
        case 'XK':
        case 'LV':
        case 'LI':
        case 'LT':
        case 'LU':
        case 'MK':
        case 'MT':
        case 'MD':
        case 'MC':
        case 'ME':
        case 'NL':
        case 'NO':
        case 'PL':
        case 'PT':
        case 'RO':
        case 'SM':
        case 'RS':
        case 'CS':
        case 'SK':
        case 'SI':
        case 'ES':
        case 'SJ':
        case 'SE':
        case 'CH':
        case 'UA':

        case 'EMEA':
            return Regions.Region_EMEA;

        case 'AI':
        case 'AG':
        case 'AW':
        case 'BS':
        case 'BB':
        case 'BZ':
        case 'BM':
        case 'BQ':
        case 'CA':
        case 'KY':
        case 'CR':
        case 'CU':
        case 'CW':
        case 'DM':
        case 'DO':
        case 'SV':
        case 'GL':
        case 'GD':
        case 'GP':
        case 'GT':
        case 'HT':
        case 'HN':
        case 'JM':
        case 'MQ':
        case 'MX':
        case 'MS':
        case 'AN':
        case 'NI':
        case 'PA':
        case 'PR':
        case 'BL':
        case 'KN':
        case 'LC':
        case 'MF':
        case 'PM':
        case 'VC':
        case 'SX':
        case 'TT':
        case 'TC':
        case 'US':
        case 'UM':
        case 'VG':
        case 'VI':
        case 'AR':
        case 'BO':
        case 'BR':
        case 'CL':
        case 'CO':
        case 'EC':
        case 'FK':
        case 'GF':
        case 'GY':
        case 'PY':
        case 'PE':
        case 'SR':
        case 'UY':
        case 'VE':

        case 'US':
            return Regions.Region_US;

        case 'GB':
        case 'UK':
            return Regions.Region_UK;

        case 'JP':
        case 'APAC':
            return Regions.Region_APAC;

        default:
            return Regions.Region_OTHER;
    }
}

export const IDsMap = [
    { "desktop_skin": ["Desktop Skin"] },
    { "mobile_skin": ["Mobile Skin"] },
    { "mobile_scroller": ["Mobile Scroller"] },
    { "inscreen_expandable_mobile": ["InScreen Expandable Mobile"] },
    { "inscreen_expandable_desktop": ["InScreen Expandable Desktop"] },
    { "inscreen_expandable": ["InScreen Expandable (ISX)"] },
    { "inscreen_expandable_video": ["InScreen Expandable Video (ISXV)"] },
    { "inimage_expandable": ["InImage Expandable"] },
    { "six_second_canvas": ["6 Second Video Canvas"] },
    { "hangtime": ["Hang Time"] },
    { "hoverboard": ["Hoverboard"] },
    { "ingame": ["InGame"] },
    { "contextual_olv": ["Contextual Online Video"] },

    { "video": ["Video"] },
    { "video_background": ["Video Background"] },
    { "changing_background": ["Changing Background"] },
    { "background_slider": ["Background Slider"] },
    { "carousel": ["Carousel"] },
    { "animation_on_scroll": ["Animation on Scroll"] },
    { "countdown": ["Countdown"] },
    { "image": ["Image"] },
    { "key_artwork": ["Key Artwork"] },

    { "animated_bg": ["Animated BG"] },
    { "animated_fg": ["Animated FG"] },
    { "parallax_effect": ["Parallax Effect"] },
    { "fixed_reveal": ["Fixed Reveal"] },

    { "full_custom": ["Fully Custom"] }
]

export const getID = (name) => {
    let id;
    IDsMap.forEach((item) => {
        for (var k in item) {
            let arr = item[k];
            if (arr.indexOf(name) > -1) {
                id = k;
                return;
            }
        }
    })
    return id;
}

export const getName = (id) => {
    let name;
    IDsMap.forEach((item) => {
        for (var k in item) {
            if (k === id) {
                name = item[k][0];
                return;
            }
        }
    })
    return name;
}

export const getPlatform = (id) => {
    switch (id) {
        case 'desktop_skin':
        case 'inscreen_expandable_desktop':
            return "desktop";

        case 'mobile_skin':
        case 'mobile_scroller':
        case 'hangtime':
        case 'hoverboard':
            return "mobile";

        default:
            return "both"
    }
}

export const getLegacySuffix = (id) => {
    switch (id) {
        case 'hangtime':
        case 'hoverboard':
            return "_xyz";

        default:
            return ""
    }
}

export const getRegionFromURL = () =>{
    for (let k in Regions){
        let region = Regions[k];
        let regx = new RegExp(`/${region.toLowerCase()}`)
        if (regx.test(window.location.href)){
            return region;
        }
    }
}

export const parseAsHeading = (string) =>{
    const words = string.split(/_|-/);
    let str = "";
    words.forEach((word, index)=>{
        str += word.charAt(0).toUpperCase() + word.slice(1);
        if (index < words.length - 1) str += " ";
    })
    return str;
}