import React, { useState, useContext, useMemo, useEffect }from 'react';
import '../app.js';
import Header from '../components/Header';
import Footer from '../components/Footer';

import TemplateSelect from '../components/TemplateSelect';
import TemplateCTAs from '../components/TemplateCTAs';
import Specs from '../components/Specs';

// import featuresDesktop from '../content/features_desktop.json';
// import featuresMobile from '../content/features_mobile.json';

import formats from '../content/formats.json';

import { RegionContext } from '../contexts/RegionContext';
import { FeatureContext } from '../contexts/FeatureContext';

import { getGuruCard } from '../guru';
import { getLegacySuffix, getName, getPlatform } from '../utilities';

import LoadingSpinner from "../components/LoadingSpinner";

import Modal from '../components/Modal'



const FinalView = (props) => {

  const {specsRegion} = useContext(RegionContext);

  const [featureID, setFeatureID] = useState("");

  const [specs, setSpecs] = useState([]);

  const [sizes, setSizes] = useState([]);

  const [features, setFeatures] = useState([]);

  const [ctaCopy, setCtaCopy] = useState([]);

  const [versions, setVersions] = useState([]);

  const [otherTables, setOtherTables] = useState([]);

  const [fullCustomMessage, setFullCustomMessage] = useState("");

  const [siteCopy, setSiteCopy] = useState([]);

  const [generalLinks, setGeneralLinks] = useState([]);

  const [viewReady, setViewReady] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [modalAction, setModalAction] = useState(()=>{})

  const title = getName(props.formatID);

  /* 
  localFeaturesIndex is currently just holding icon info. 
  For now we are using the same icon for the feature as for the format, from inside formats.json
  */

  // let localFeaturesIndex = getPlatform(props.formatID) === "mobile" ? featuresMobile : featuresDesktop;



  let localIcon = formats.find(it => it.name === props.formatID);
  localIcon = localIcon && localIcon.value.icon;

  const showFullCustomModal = (func)=>{
    setModalAction(()=> func);
    setModalIsOpen(true)
  }

  useMemo(()=>{

    const omitValueNames = [
      "PSD Template",
      "HTML Boilerplate",
      "HTML Template",
      "Demo"
    ];

    getGuruCard("site_copy", (tableData)=>{
      let siteC = tableData.find(item => item.name === "site_copy");
      setSiteCopy((siteC && siteC.value) || [])
      let fullCustomMessage = siteC && siteC.value.find(item => item.name === "full_custom_message")
      fullCustomMessage = fullCustomMessage && fullCustomMessage.value;
      setFullCustomMessage(fullCustomMessage || "");
    })

    getGuruCard(props.formatID, (dataArray)=>{
      var arr = [], availableLinks = [], sizesArr = [], ctaCopyArr = [], versionsArr = [], otherTablesArr = [];
      dataArray.forEach((item)=>{
        if (item.name === "general"){
          let innerDataArray = item.value;
          innerDataArray.forEach((item, index)=>{
            if (omitValueNames.indexOf(item.name) < 0){
              arr.push(item);
            }
          })
        } else if (item.name === "sizes"){
          let innerDataArray = item.value;
          innerDataArray.forEach((item)=>{
            sizesArr.push(item)
          })
        } else if (item.name === "links"){
          let innerDataArray = item.value;
          innerDataArray.forEach((item)=>{
            availableLinks.push(item)
          })
        } else if (item.name === "cta_copy"){
          let innerDataArray = item.value;
          innerDataArray.forEach((item)=>{
            ctaCopyArr.push(item)
          })
        } else if (item.name === "versions"){
          let innerDataArray = item.value;
          innerDataArray.forEach((item)=>{
            versionsArr.push(item)
          })
        } else {
          let innerDataArray = item.value;
          let otherData = {name:item.name, value:item.value};
          // innerDataArray.forEach((item)=>{
          //   otherDataArray.push(item)
          // });
          otherTablesArr.push(otherData)
        }
      })
      arr.push({name:"Sizes", value:""})
      arr = arr.concat(sizesArr);

      setSpecs(arr);
      setSizes(sizesArr);
      setCtaCopy(ctaCopyArr);
      setVersions(versionsArr);
      setOtherTables(otherTablesArr);

      getGuruCard(getPlatform(props.formatID)==="mobile" ? "features_mobile" : "features_desktop", (featureSpecsArr)=>{
        /* 
        availableLinks used to determine what feature templates are available
        compile features list from default info and availableLinks specific to this format
        */
        let availableFeatures = [];
          availableLinks.forEach((linksItem)=>{
            /* 
            let icon = localFeaturesIndex.find(it => it.name === linksItem.name) || localFeaturesIndex.find(it => it.name === "default");
            if (icon) icon = icon.value;
            if (icon) icon = icon.icon;
            */
            let featureSpecs = featureSpecsArr.find(it => it.name === linksItem.name);
            let featureItem = {
              name:linksItem.name,
              value:[
                {name:"links", value:linksItem.value},
                {name:"icon", value:localIcon},
                {name:"specs", value:featureSpecs && featureSpecs.value}
              ]
            }
            availableFeatures.push(featureItem)
          })
          
        let links = featureSpecsArr.find(it => it.name === "links");
        setGeneralLinks(links && links.value || [])
        setFeatures(availableFeatures);
        setViewReady(true)
      })
    })
  }, [props.formatID, localIcon]);

  useEffect(()=>{
    document.querySelector("main").style.opacity = 1;
  }, [])

return (
  <>
    <div className='view-container choose-format'>
      <Header title={'GumGum Smart Designs<sup>TM</sup>'} sub_title={title} regionName={specsRegion}/>
      <main>
        {
          viewReady ? (<FeatureContext.Provider value={{featureID, setFeatureID}}>
            {
              features.length ? (<><TemplateSelect title={title} specs={specs} features={features} siteCopy={siteCopy} versions={versions}></TemplateSelect>
                <div className="line"></div>
                <TemplateCTAs title={title} features={features} generalLinks={generalLinks} ctaCopy={ctaCopy} showFullCustomModal={showFullCustomModal}></TemplateCTAs>
                <div className="line"></div></>) : null
            }
            <Specs title={title} specs={specs} features={features} sizes={sizes} otherTables={otherTables}></Specs>
            </FeatureContext.Provider>) : (<LoadingSpinner/>)
        }
      </main>
      <Footer/>
    </div>
    <Modal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} title={"Fully Custom"} message={fullCustomMessage} checkbox={true} action={modalAction}></Modal>
  </>
)
}

export default FinalView;