import React  from 'react';
import { parseAsHeading } from '../utilities';

const OtherTable = (props) => {

    const getFootnote = ()=>{
        return  props.tableData.value.find(it => it.name === "Footnote");
    }
  
return (
     <div className='inner-content'>
        <h3>{parseAsHeading(props.tableData.name)}</h3>
        <table className='single-column'><tbody>
            {
                props.tableData.value.map((item, index)=>{
                    if (/Footnote/.test(item.name)) return null;
                    return <tr key={index}>
                        { 
                            (item.value && item.value.length) ? (<td><strong>{item.name}</strong></td>) : (<td>{item.name}</td>)
                        } 
                        {
                            item.value.map ? (
                                item.value.map((item, index2)=>{
                                    return  <td key={index2} dangerouslySetInnerHTML={{__html: item}}></td>
                                })
                            ) : (
                                <td dangerouslySetInnerHTML={{__html: item.value}}></td>
                            )
                        }
                    </tr>
                })
                
            }
        </tbody></table>
        {
            getFootnote() ? (<p className='table-footnote single-column' dangerouslySetInnerHTML={{__html:getFootnote().value}}></p>) : (null)
        }
    </div>
)
}

export default OtherTable;